<template>
  <v-dialog v-model="dialog" scrollable persistent>
    <v-card>
      <v-card-title class="headline" v-if="view.id">
        {{ $t('rule.edittitle') }}</v-card-title
      >
      <v-card-title class="headline" v-if="!view.id">{{
        $t('rule.addtitle')
      }}</v-card-title>
      <v-card-text>
        <v-form v-model="valid" lazy-validation>
          <v-text-field
            v-model="view.name"
            :label="$t('rule.name')"
            required
            :rules="[(v) => !!v || $t('all.fieldnoempty')]"
          ></v-text-field>
          <v-card flat>
            <v-card-text>
              <p>{{ $t('rule.resources') }}</p>
              <v-switch
                v-for="item in resources"
                v-bind:key="item.id"
                v-model="item.checked"
                v-bind:label="item.group_name + ' (' + $t(item.name) + ')'"
                color="primary"
                hide-details
              ></v-switch>
            </v-card-text>
          </v-card>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="error" small v-if="btnDel" flat @click.native="del">
          {{ $t('all.delete') }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn flat="flat" small @click.native="$emit('close')">
          {{ $t('all.close') }}
        </v-btn>
        <v-btn color="primary" small @click.native="save">{{
          $t('all.save')
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['title', 'view'],
  data() {
    return {
      dialog: true,
      valid: false,
      resources: [],
      iname: '',
      irule: '',
      errName: false,
      errRes: false,
      btnDel: false,
    }
  },
  computed: {},
  mounted: function () {
    var t = this,
      rule = this.view.id ? this.view.id : ''
    this.iname = this.view.name ? this.view.name : ''
    this.irule = this.view.id ? this.view.id : ''
    this.btnDel = this.view.id ? true : false
    this.$http.post(this.$store.state.apiUrl + 'rule/Resource/' + rule).then(
      (response) => {
        t.resources = response.body
      },
      (err) => {
        console.log(err)
      }
    )
  },
  methods: {
    del: function () {
      console.log(123)
      this.$emit('delete', this.view)
    },
    save: function () {
      var i = 0,
        prmRes = [],
        t = this

      var arr = Object.assign({}, this.view)
      for (var res in this.resources) {
        //                console.log(this.resources[res].checked);
        if (this.resources[res].checked == true) {
          prmRes[i] = this.resources[res].id
          i++
        }
      }
      console.log(arr)
      if (this.valid) {
        this.$http
          .post(this.$store.state.apiUrl + 'rule/save/', {
            id: arr.id,
            name: arr.name,
            resource: prmRes,
          })
          .then(
            (response) => {
              t.$store.dispatch('setMessage', {
                type: response.body.err == 1 ? 'error' : 'success',
                message: t.$t(response.body.msg),
              })
              if (!response.body.err) {
                this.$emit('close', 'reload')
              }
              //                    t.ResultLogin=response.body;
              //                    this.$store.commit('mLogin', t.ResultLogin)
              //                    console.log(this.$route.query.redirect);
              //                    console.log(this.redirectToAfterLogin);
              //                    this.$router.push(this.redirectToAfterLogin)
            },
            (err) => {
              console.log(err)
            }
          )
      }
    },
  },
}
</script>

<style>
.modal-body {
  max-height: calc(100vh - 212px);
  overflow: auto;
}
</style>
